import { useState, useCallback, useEffect, useRef } from "react";
import { 
    Container, Box, Button, TabPanels, Circle,
    IconButton, HStack, Spacer, Text, Input, 
    Flex, Center, Accordion, AccordionItem, AccordionButton,
    AccordionPanel, AccordionIcon, Tabs, TabList, Tab,
    Card, CardBody, Skeleton,
    TabPanel, CardHeader,
    useToast, Spinner} from "@chakra-ui/react";
import { RepeatIcon, DownloadIcon } from '@chakra-ui/icons';
import { IoIosSend } from "react-icons/io";
import { IoDocumentText } from "react-icons/io5";

import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import {newTheme} from '../components/mdstyle';

import { usePageColor } from "../contexts/ColorContext";

import { getBaseUrl } from "../Api";
import ReactMarkdown from "react-markdown";

const ServicePage = () => {
    const colors = usePageColor('doc');
    const [searchKeyword, setSearchKeyword] = useState('');
    const [allDocumentData, setAllDocumentData] = useState({});
    const [currentDocumentData, setCurrentDocumentData] = useState('');
    const [currentTitleData, setCurrentTitleData] = useState('');
    const [keywordError, setKeywordError] = useState('');
    const [isNewsFetching, setIsNewsFetching] = useState(false);    
    const [newsType, setNewsType] = useState(0); // 기사생성에서 사용할 타입
    const [currentType, setCurrentType] = useState(0); // 히스토리에서 사용할 타입
    const [activeTab, setActiveTab] = useState(0); // 활성화된 탭의 인덱스 저장
    const toast = useToast();
    const [groupedArticles, setGroupedArticles] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // 모든 a 태그에 target="_blank" 속성을 강제 추가(새 창에서 열기)
        const links = document.querySelectorAll("a");
        links.forEach(link => {
            link.setAttribute("target", "_blank");
            link.setAttribute("rel", "noopener noreferrer");
        });
    }, [currentDocumentData]);


    const fetchNews = useCallback(async(whatNews) => {
        let baseUrl = `${getBaseUrl()}/api`;
        if (whatNews === 2 && !searchKeyword.trim()) {
            setKeywordError('키워드를 입력해주세요.'); // 에러 메시지 설정
            return; // 키워드가 없으면 함수 종료
        }
        setKeywordError(''); // 키워드가 정상적으로 입력된 경우 에러 메시지 초기화

        try {
            setIsNewsFetching(true);
            console.log(`Fetching news with type: ${whatNews}`);
    
            if (whatNews === 0) {
                const response = await fetch(`${baseUrl}/get_naver_top10`);
                if (!response.ok || response.status === 500) {
                    toast({
                        title: "기사 생성에 실패하였습니다.",
                        description: "잠시 후 다시 시도해주세요.",
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    });
                    setIsNewsFetching(false);
                    return;
                }
                const data = await response.json();
                setAllDocumentData(data.result);
                setNewsType(0);
                setIsNewsFetching(false);
                renderDocument('ko', 0); // 기사 생성 완료 후 바로 한국어로 렌더링
            } else if (whatNews === 1) {
                const response = await fetch(`${baseUrl}/get_chosunbiz_top5`);
                if (!response.ok || response.status === 500) {
                    toast({
                        title: "기사 생성에 실패하였습니다.",
                        description: "잠시 후 다시 시도해주세요.",
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    });
                    setIsNewsFetching(false);
                    return;
                }
                const data = await response.json();
                setAllDocumentData(data.result);
                setNewsType(1);
                setIsNewsFetching(false);
                renderDocument('ko', 1); // 기사 생성 완료 후 바로 한국어로 렌더링
            } else if (whatNews === 2) {
                // 검색 키워드를 이용해 화제성 기사 생성
                console.log('Updating topic state with keyword:', searchKeyword);
                console.log("searchKeyword : ", searchKeyword);
                const response = await fetch(`${baseUrl}/create_topic_news/${searchKeyword}`);
                if (!response.ok || response.status === 500) {
                    toast({
                        title: "기사 생성에 실패하였습니다.",
                        description: "잠시 후 다시 시도해주세요.",
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    });
                    setIsNewsFetching(false);
                    return;
                }
                const data = await response.json();
                console.log('Topic news created:', data.result);
                setAllDocumentData(data.result);
                setNewsType(2);
                setIsNewsFetching(false);
                renderDocument('ko', 2); // 기사 생성 완료 후 바로 한국어로 렌더링
                setSearchKeyword('');
            }
        } catch (error) {
            console.error('Error fetching news:', error);
            toast({
                title: "기사 생성에 실패하였습니다.",
                description: "잠시 후 다시 시도해주세요.",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            setIsNewsFetching(false);
        }
    }, [setIsNewsFetching, setNewsType, searchKeyword]);
    
   
    const fetchArticles = async () => {
        let baseUrl = `${getBaseUrl()}/api`;
        try {
            setIsLoading(true);
            const response = await fetch(`${baseUrl}/history`, { headers: { 'Content-Type': 'application/json' } });
            const data = await response.json();
            const groupedData = {};

            data.news_list.forEach(article => {
                const date = new Date(article[1]).toLocaleDateString();
                const articleData = { title: article[0], doc_id: article[2], source_type: article[3] };

                // 날짜를 기준으로 그룹화
                if (!groupedData[date]) {
                    groupedData[date] = {
                        naver: [],
                        chosunbiz: [],
                        topic: []
                    };
                }

                // source_type에 따라 분류
                if (article[3] === 'naver') {
                    groupedData[date].naver.push(articleData);  // 네이버 기사 추가
                } else if (article[3] === 'chosunbiz') {
                    groupedData[date].chosunbiz.push(articleData);  // 조선비즈 기사 추가
                } else if (article[3] === 'topic') {
                    groupedData[date].topic.push(articleData);  // 화제성 기사 추가
                }
            });

            // 각 날짜별로 doc_id 순으로 정렬 (큰 순서대로)
            Object.keys(groupedData).forEach(date => {
                groupedData[date].naver.sort((a, b) => parseInt(b.doc_id) - parseInt(a.doc_id));
                groupedData[date].chosunbiz.sort((a, b) => parseInt(b.doc_id) - parseInt(a.doc_id));
                groupedData[date].topic.sort((a, b) => parseInt(b.doc_id) - parseInt(a.doc_id));
            });

            setGroupedArticles(groupedData);  // 정리된 데이터를 상태로 설정
        } catch (error) {
            setIsLoading(false);
            console.error("Error fetching articles:", error);
        } finally {
            setIsLoading(false);  // 로딩 상태 해제
        }
    };

    // 탭 변경 시 렌더링 중인 문서를 초기화
    useEffect(() => {
        if (activeTab === 0) {
            setCurrentDocumentData('');
            setCurrentTitleData('');
            setCurrentType(null);
            setNewsType(null);
            setSearchKeyword('');
        } else if (activeTab === 1) {
            setCurrentDocumentData('');
            setCurrentTitleData('');
            setCurrentType(null);
            setNewsType(null);
            setSearchKeyword('');
        }
    }, [activeTab]);
    

    //히스토리 출력
    const renderArticles = () => {
        if (isLoading) {
            return (
                <Flex align="center" justify="center" height="100%">
                    <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="xl"
                        mt="10"
                    />
                </Flex>
            );
        }
        return (
            <Accordion allowMultiple>
                {Object.keys(groupedArticles).map((date, dateIndex) => (
                    <AccordionItem key={date} mb="4" borderRadius="16" boxShadow="md" overflow="hidden">
                        <h2>
                            <AccordionButton  bg="#F4F4F9" _hover={{bg:"#E0E0E5"}}>
                                <Box flex="1" textAlign="left" fontWeight="bold">{date}</Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            {/* 네이버 Top10 */}
                            {groupedArticles[date].naver.length > 0 && (
                                <>
                                    <Text fontWeight="bold" mt="4" mb="2">네이버 Top10</Text>
                                    {groupedArticles[date].naver.map(({ title, doc_id, source_type}, index) => (
                                        <HStack 
                                            key={doc_id} 
                                            mt={2} 
                                            onClick={() => fetchArticleContent(doc_id, source_type)} cursor="pointer" borderBottom={index !== groupedArticles[date].length - 1 ? "1px solid gray" : "none"} 
                                            pb={2}>
                                            <Circle size="35px" fontWeight="bold">
                                                {index + 1}
                                            </Circle>
                                            <Text flex="1" ml="3">
                                                {title}
                                            </Text>
                                        </HStack>
                                    ))}
                                </>
                            )}

                            {/* 조선비즈 Top10 */}
                            {groupedArticles[date].chosunbiz.length > 0 && (
                                <>
                                    <Text fontWeight="bold" mt="4" mb="2">조선비즈 Top5</Text>
                                    {groupedArticles[date].chosunbiz.map(({ title, doc_id, source_type }, index) => (
                                        <HStack 
                                            key={doc_id} 
                                            mt={2} 
                                            onClick={() => fetchArticleContent(doc_id, source_type)} cursor="pointer" borderBottom={index !== groupedArticles[date].length - 1 ? "1px solid gray" : "none"} 
                                            pb={2}>
                                            <Circle size="35px" fontWeight="bold">
                                                {index + 1}
                                            </Circle>
                                            <Text flex="1" ml="3">
                                                {title}
                                            </Text>
                                        </HStack>
                                    ))}
                                </>
                            )}

                            {/* 화제성 기사 */}
                            {groupedArticles[date].topic.length > 0 && (
                                <>
                                    <Text fontWeight="bold" mt="4" mb="2">화제성 기사</Text>
                                    {groupedArticles[date].topic.map(({ title, doc_id, source_type }, index) => (
                                        <HStack 
                                            key={doc_id} 
                                            mt={2} 
                                            onClick={() => fetchArticleContent(doc_id, source_type)} cursor="pointer" borderBottom={index !== groupedArticles[date].length - 1 ? "1px solid gray" : "none"} 
                                            pb={2}>
                                            <Circle size="35px" fontWeight="bold">
                                                {index + 1}
                                            </Circle>
                                            <Text flex="1" ml="3">
                                                {title}
                                            </Text>
                                        </HStack>
                                    ))}
                                </>
                            )}
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
                

        );
    };

    const fetchArticleContent = async (doc_id, source_type) => {
        let baseUrl = `${getBaseUrl()}/api`;
        try {
            const response = await fetch(baseUrl + `/history/${doc_id}?source_type=${source_type}`);
            const data = await response.json();
            let updatedData = {};
            let type = 0;

            if (source_type === 'naver') {
                updatedData = { main_title: data.title, naver_summarized_data: data.content, main_title_eng: data.title_eng, naver_summarized_data_eng: data.content_eng, main_title_jap: data.title_jap, naver_summarized_data_jap: data.content_jap };
                type = 0;
            } else if (source_type === 'chosunbiz') {
                updatedData = { main_title: data.title, chosunbiz_summarized_data: data.content, main_title_eng: data.title_eng, chosunbiz_summarized_data_eng: data.content_eng, main_title_jap: data.title_jap, chosunbiz_summarized_data_jap: data.content_jap };
                type = 1;
            } else if (source_type === 'topic') {
                updatedData = { title: data.title, content: data.content, title_eng: data.title_eng, content_eng: data.content_eng, title_jap: data.title_jap, content_jap: data.content_jap };
                type = 2;
            }
            // 데이터가 업데이트되면 바로 렌더링
            setAllDocumentData(updatedData);
            setCurrentType(type);
        } catch (error) {
            console.error("Error fetching article content:", error);
            toast({
                title: "히스토리 연결 실패",
                description: "잠시 후 다시 시도해주세요.",
                status: "error",
                isClosable: true,
            });
        }
    };
    
    useEffect(() => {
        fetchArticles(); // 페이지 로드 시 히스토리 데이터 가져옴
    }, []);



    const renderDocument = useCallback((lang, type) => {
        try{
            if (type === 0) {
                if (lang === "ko") {
                    setCurrentTitleData(allDocumentData?.main_title || '');
                    setCurrentDocumentData(allDocumentData?.naver_summarized_data || '');
                } else if (lang === "en") {
                    setCurrentTitleData(allDocumentData?.main_title_eng || '');
                    setCurrentDocumentData(allDocumentData?.naver_summarized_data_eng || '');
                } else if (lang === "jp") {
                    setCurrentTitleData(allDocumentData?.main_title_jap || '');
                    setCurrentDocumentData(allDocumentData?.naver_summarized_data_jap || '');
                }
            } else if (type === 1) {
                if (lang === "ko") {
                    setCurrentTitleData(allDocumentData?.main_title || '');
                    setCurrentDocumentData(allDocumentData?.chosunbiz_summarized_data || '');
                } else if (lang === "en") {
                    setCurrentTitleData(allDocumentData?.main_title_eng || '');
                    setCurrentDocumentData(allDocumentData?.chosunbiz_summarized_data_eng || '');
                } else if (lang === "jp") {
                    setCurrentTitleData(allDocumentData?.main_title_jap || '');
                    setCurrentDocumentData(allDocumentData?.chosunbiz_summarized_data_jap || '');
                }
            } else if (type === 2) {
                if (lang === "ko") {
                    setCurrentTitleData(allDocumentData?.title || '');
                    setCurrentDocumentData(allDocumentData?.content || '');
                } else if (lang === "en") {
                    setCurrentTitleData(allDocumentData?.title_eng || '');
                    setCurrentDocumentData(allDocumentData?.content_eng || '');
                } else if (lang === "jp") {
                    setCurrentTitleData(allDocumentData?.title_jap || '');
                    setCurrentDocumentData(allDocumentData?.content_jap || '');
                }
            }
        } catch {
            toast({
                title: "기사 생성 실패",
                description: "잠시 후 다시 시도해주세요.",
                status: "error",
                isClosable: true,
            });
        }
    }, [allDocumentData]);

    useEffect(() => {
        if (Object.keys(allDocumentData).length > 0) {
            if (currentType === 0) {
                // 네이버 기사일 경우
                renderDocument('ko', 0); // 'ko'로 네이버 기사 렌더링
            } else if (currentType === 1) {
                // 조선비즈 기사일 경우
                renderDocument('ko', 1); // 'ko'로 조선비즈 기사 렌더링
            } else if (currentType === 2) {
                // 화제성 기사일 경우
                renderDocument('ko', 2); // 'ko'로 화제성 기사 렌더링
            }
        }
    }, [currentType, allDocumentData, renderDocument]);
    


    useEffect(() => {
        if (Object.keys(allDocumentData).length > 0) {
            renderDocument('ko', newsType);  // 상태가 업데이트된 후 자동으로 한국어로 렌더링
        }
    }, [allDocumentData, newsType, renderDocument]);


    const handleLanguageChange = (lang) => {
        if (activeTab === 0) renderDocument(lang, newsType);  // 기사 생성 탭에서 `newsType` 사용
        else if (activeTab === 1) renderDocument(lang, currentType); // 히스토리 탭에서 `currentType` 사용
    };

    const handleGetFileDownload = async () => {
        let baseUrl = `${getBaseUrl()}/api`;
        const response = await fetch(`${baseUrl}/download`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              file_type: "docx",  // docx 타입으로 다운로드
              doc_name: currentTitleData,  // 제목
              doc_content: currentDocumentData,  // 렌더링된 본문 내용
            }),
          });

          if (response.ok) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${currentTitleData}.docx`;
            document.body.appendChild(a);
            a.click();
            a.remove();
          } else {
            toast({
                title: "다운로드 실패",
                description: "잠시 후 다시 시도해주세요.",
                status: "error",
                isClosable: true,
            });
          }
    };


    return (
        <Container bg={colors.ContainerMainBg} maxW='' size='container.3xl'>
            <HStack maxW="100%">                
                <Container maxW="35%" h="95vh" color={colors.ContainerPromptColor}>
                    <Flex direction="column" h="98%">
                        <Box overflow="auto">
                            <Tabs onChange={(index) => setActiveTab(index)} isFitted variant='enclosed' mt="5">
                                <TabList borderColor="#C0D1D4">
                                    <Tab _selected={{ fontWeight: "bold", bg: "gray.200", transform: "translateY(-2px)", boxShadow:"md" }} _hover={{ boxShadow: "md", bg: "gray.300" }}>기사생성</Tab>
                                    <Tab _selected={{ fontWeight: "bold", bg: "gray.200", transform: "translateY(-2px)", boxShadow:"md" }} _hover={{ boxShadow: "md", bg: "gray.300" }}>히스토리</Tab>
                                    <IconButton
                                        aria-label="새로고침"
                                        icon={<RepeatIcon />}
                                        onClick={fetchArticles}  // 버튼을 클릭하면 새로고침 함수 실행
                                        bg="transparent"
                                    />
                                </TabList>
                                <TabPanels>
                                    <TabPanel>
                                        {isNewsFetching ? (
                                            <Flex direction="column" align="center" justify="center" h="100%">
                                                <Spinner
                                                    thickness="4px"
                                                    speed="0.65s"
                                                    emptyColor="gray.200"
                                                    color="blue.500"
                                                    size="xl"
                                                    mt="10"
                                                />
                                                <Text mt={4} fontSize="lg" fontWeight="bold" color="gray.600">
                                                    기사 생성 중입니다. 잠시만 기다려 주세요.
                                                </Text>
                                            </Flex>
                                        ) : (
                                            <Flex direction="column" flex="100">                                                            
                                                <Card borderRadius="16px" mt="5" boxShadow="lg" bg="#F4F4F9">
                                                    <CardBody>
                                                        <HStack>
                                                            <Button rightIcon={<IoDocumentText />} bg="#B0D4F1" borderRadius="16px" boxShadow="md" _hover={{ bg: "#7FB3D5" }} flex={1} onClick={() => fetchNews(0)}>
                                                                네이버연예 Top10 기사
                                                            </Button>
                                                            <Button rightIcon={<IoDocumentText />} bg="#B0D4F1" borderRadius="16px" boxShadow="md" _hover={{ bg: "#7FB3D5" }} flex={1} onClick={() => fetchNews(1)}>
                                                                조선비즈 Top5 기사
                                                            </Button>                                  
                                                        </HStack>                     
                                                    </CardBody>
                                                </Card>
                                                <Spacer />
                                                <Card borderRadius="16px" mt="5" boxShadow="lg"  bg="#F4F4F9">
                                                    <CardHeader>
                                                        <HStack>
                                                            <Text fontSize="xl" fontWeight="bold">검색 키워드 입력</Text>
                                                            <Spacer />
                                                            <Button rightIcon={<IoIosSend />} _hover={{ bg: "#E3C48F"}} bg="#F5DEB3" borderRadius="16px" boxShadow="md" onClick={() => fetchNews(2)}>
                                                                화제성 기사 생성
                                                            </Button>                                       
                                                        </HStack>
                                                    </CardHeader>                                   
                                                    <CardBody>
                                                        <Input placeholder="검색 키워드 입력" onChange={(e) => setSearchKeyword(e.target.value)} value={searchKeyword} borderRadius="16px" bg="white"/>
                                                        {keywordError && <Text color="red.500" mt="2" fontSize="sm">{keywordError}</Text>}
                                                    </CardBody>
                                                </Card>
                                            </Flex>
                                        )}
                                    </TabPanel>
                                    <TabPanel>
                                        {renderArticles()}
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </Box>                        
                    </Flex>
                </Container>
     
                <Container maxW="65%" h="95vh" bg={colors.ContainerMainBg} color={colors.ContainerMainColor}>
                    <Flex direction="column" h="95%">
                        <Box borderWidth="2px" borderRadius="16px" h="95%" mt="2%" overflowY="scroll" p="3" borderColor="#C0D1D4">
                            <Container maxW="container.xl">
                                {isNewsFetching ? (
                                    <Box>
                                        <Skeleton height="100px" mb="4" mt="7" borderRadius="16px"/>
                                        <Skeleton height="700px" mb="4" borderRadius="16px" />
                                    </Box>
                                ) : (
                                    <Box>
                                    <Card mt="5" borderRadius="16px" bg="#F4F4F9">
                                        <CardBody>
                                            <Flex>
                                                <Center fontSize="2xl" fontWeight="bold" borderRadius="16px" maxW="70%" whiteSpace="normal" wordBreak="break-word">
                                                    {currentTitleData}
                                                </Center>
                                                <Spacer />
                                                <HStack>
                                                    <Button onClick={() => handleLanguageChange("ko")} boxShadow="md" borderRadius="16px" rightIcon={<RepeatIcon />} bg="#B0D1E1" _hover={{bg:"#7FB3D5"}}>한국어</Button>
                                                    <Button onClick={() => handleLanguageChange("en")} boxShadow="md" borderRadius="16px" rightIcon={<RepeatIcon />} bg="#B0D1E1" _hover={{bg:"#7FB3D5"}}>English</Button>
                                                    <Button onClick={() => handleLanguageChange("jp")} boxShadow="md" borderRadius="16px" rightIcon={<RepeatIcon />} bg="#B0D1E1" _hover={{bg:"#7FB3D5"}}>日本語</Button>
                                                    <IconButton onClick={handleGetFileDownload} icon={<DownloadIcon />} boxShadow="md" borderRadius="16px" bg="#B0D1E1" _hover={{bg:"#7FB3D5"}}/>
                                                </HStack>
                                            </Flex>                                
                                        </CardBody>
                                    </Card>
                                    <Box mt="7">
                                        <ReactMarkdown components={ChakraUIRenderer({newTheme})}>
                                            {currentDocumentData}
                                        </ReactMarkdown>
                                    </Box>
                                </Box>
                                )}
                            </Container>
                        </Box>
                    </Flex>
                </Container>
            </HStack>
        </Container>
    );
}

export default ServicePage;
