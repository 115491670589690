import { Container, Box, IconButton, HStack, Text, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { CloseIcon } from '@chakra-ui/icons';
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGlobal } from "../contexts/GlobalContext";
import ServicePage from "../pages/ServicePage";

const NavigationBar = ({stopAllFunctions}) => {
    const navigate = useNavigate();
    const [tokenAndSessionInfo] = useState({});
    
    const { isSignedIn } = useGlobal();

    const { isOpen: isControlCenterOpen, onClose: onControlCenterClose } = useDisclosure();

    const navigationBarBg = useColorModeValue('#1E2A3A', '#333333');
    const navigationBarColor = useColorModeValue('black', 'white');
    const controlCenterBg = useColorModeValue('#333333', '#e9e9e9');
    const controlCenterColor = useColorModeValue('white', 'black');

    const [currentPage, setCurrentPage] = useState(window.location.pathname);

    const handleReload = () => {
        if (stopAllFunctions) {
          stopAllFunctions();  // ServicePage의 모든 동작 중지
          setTimeout(() => {
            console.log("동작 중단 후 새로고침");
            window.location.reload();  // 새로고침
          }, 100);
        } else {
          console.log("새로고침");
          window.location.reload();  // 중단할 동작이 없으면 바로 새로고침
        }
      };
    

    useEffect(() => {
        if (!isSignedIn && (currentPage === '/rag') ) {
            navigate('/signin');
        } else if (isSignedIn && currentPage === '/signin') {
            navigate('/rag');
        }
    }, [isSignedIn, currentPage, navigate]);

    useEffect(() => {
        const handleLocationChange = () => {
            setCurrentPage(window.location.pathname);
        };
    
        window.addEventListener('popstate', handleLocationChange);
    
        return () => {
            window.removeEventListener('popstate', handleLocationChange);
        };
    }, []);

    return (
        <Container
            maxW='100%'
            size="container.3xl"
            h='7vh'
            bg={navigationBarBg}
            color={navigationBarColor}
        >
            <HStack
                alignItems="center" 
                h="100%" 
                >
                <HStack>
                    <img
                    src={`${process.env.PUBLIC_URL}/ci_chosunbiz_long.png`}
                    alt="Logo"
                    className="logo"
                    width="130"
                    height="auto"   
                    style={{ cursor: 'pointer' }}
                    onClick={handleReload}
                    />
                    <Text fontWeight="bold" fontSize="20" color="white" cursor="pointer" onClick={handleReload}>
                    AI Service
                    </Text>
                </HStack>
            </HStack>

            {isControlCenterOpen && (
                <Box
                    position="fixed"
                    top="16"
                    right="4"
                    width="300px"
                    padding="4"
                    backgroundColor={controlCenterBg}
                    color={controlCenterColor}
                    borderRadius="13px"
                    boxShadow="lg"
                    backdropFilter="blur(10px)"
                    zIndex="10"
                    opacity="1"
                >
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Text fontWeight="bold">Control Center</Text>
                        <IconButton 
                            icon={<CloseIcon />} 
                            size="sm" 
                            onClick={onControlCenterClose} 
                            aria-label="Close"
                            backgroundColor="transparent"
                            _hover={{ backgroundColor: "whiteAlpha.300" }}
                        />
                    </Box>
                    <Text mt="2">
                    세션 만료시간
                    <br />
                    {tokenAndSessionInfo.sessionExpireTime? tokenAndSessionInfo.sessionExpireTime: '세션 만료시간을 확인할 수 없습니다.'}
                    </Text>
                    <Text mt="2">
                    엑세스 토큰 만료시간
                    <br />
                    {tokenAndSessionInfo.accessTokenExpireTime? tokenAndSessionInfo.accessTokenExpireTime: '엑세스 토큰 만료시간을 확인할 수 없습니다.'}
                    </Text>
                    <Text mt="2">
                    리프레시 토큰 만료시간
                    <br /> 
                    {tokenAndSessionInfo.refreshTokenExpireTime? tokenAndSessionInfo.refreshTokenExpireTime: '리프레시 토큰 만료시간을 확인할 수 없습니다.'}
                    </Text>
                </Box>
            )}
            
        </Container>
        
    );
};

export default NavigationBar;
